import React, {useEffect} from 'react';
import {getGlobal, useGlobal} from 'reactn';
import './App.sass';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Home from './pages/Home';
import Report from './pages/Report';
import LogViewer from './pages/Log';
import Config from './config';
import {useDispatch, useSelector} from "react-redux";
import jwtDecode from 'jwt-decode';
import setAuthToken from "./actions/setAuthToken";
import initIO from "./actions/initIO";
import {setGlobal} from "reactn";
import {useToasts} from "react-toast-notifications";
import { AppProvider } from "./context/AppContext";

const App = () => {

    // const [cookies, setCookie, removeCookie] = useCookies(['EPOINTS_SSO']);
    const dispatch = useDispatch();
    const {addToast} = useToasts();
    const setRouteLists = useGlobal('routeLists')[1];
    const setParentDomain = useGlobal('parentDomain')[1];
    // const io = useSelector(state => state.io.io);

    // const epointsToken = cookies.EPOINTS_SSO;

    const token = useGlobal('token')[0];
    const setStartingPoint = useGlobal('entryPath')[1];

    const toHome = token && <Redirect to="/"/>;
    const toLogin = !token && <Redirect to="/login"/>;

    if (!['dark', 'light'].includes(Config.theme)) Config.theme = 'light';
    
    useEffect(() => {
        // nhan post message tu parent
        window.addEventListener('message', function (event) {
            var data = event.data;
            if(data.func === 'sendData'){
                getDataFromPortal(data.message);
            }
        });

    }, []);

    const getDataFromPortal = (data) => {
        const routeLists = data.route_lists;
        const parentDomain = data.parent_domain;
        console.log({parentDomain});
        setRouteLists(routeLists);
        setParentDomain(parentDomain);
    }

    if (!window.loaded) {
        setStartingPoint(window.location.pathname);
        const splitPath = window.location.pathname.split('/');
        const route = splitPath[1];
        const token = splitPath[2];
        if (route === 'login' && token && token.length > 20) {
            let decoded;
            try {
                decoded = jwtDecode(token);
                if (typeof decoded !== 'object' || typeof decoded.id !== 'string') return;
                setAuthToken(token);
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(decoded));
                setGlobal({
                    user: decoded,
                    token,
                }).then(() => {
                    dispatch(initIO(token));
                });
            } catch (e) {
                addToast("Invalid token provided in URL. You can still login manually.", {
                    appearance: 'error',
                    autoDismiss: true,
                });
            }
        }
        window.loaded = true
    }

    return (
        <div className={`theme ${Config.theme}`}>
            <AppProvider>
                <Router>
                    <Switch>
                        <Route path="/log-viewer">
                            <LogViewer/>
                        </Route>
                        <Route path="/report">
                            <Report/>
                        </Route>
                        <Route path="/">
                            {toLogin}
                            {!toLogin && <Home/>}
                        </Route>
                    </Switch>
                </Router>
            </AppProvider>
        </div>
    );
}

export default App;
